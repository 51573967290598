.result-card-heading {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.result-card-heading > *:first-child {
  width: 90%;
}

.result-card {
  border: none;
}

.align-elements {
  display: flex;
  justify-content: space-between;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.buttonstyle {
  background-color: black;
  color: white;
  height: fit-content;
  width: fit-content;
  padding-right: 1em;
  padding-left: 1em;
}

.quick-add-container {
  position: relative;
  display: inline-block;
  text-align: center;
}

.quick-add ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.quick-add li {
  padding: 8px 12px;
}

.quick-add li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.results-page-big-search-icon {
  width: 100px;
}

.result-card-scientific-name {
  margin-top: -8px;
  margin-bottom: 0;
}

.result-card-img {
  height: 256px;
  max-height: 256px;
  object-fit: cover;
  border-radius: 3px;
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.loading-card-img {
  height: 256px;
  max-height: 256px;
  border-radius: 3px;
  background-color: #f5f5f5;
  display: flex;
}

.result-card-img-container {
  position: relative;
}

.result-card-icons {
  position: absolute;
  top: 0;
}

.result-card-icons img {
  display: inline-block;
  width: 58px;
  height: 58px;
  margin: 0.5rem;
  margin-right: 0;
}

.label-demibold {
  margin-bottom: 1;
}
