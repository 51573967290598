@media (max-width: 991px) {
  .result-ops-pagination-container {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .result-ops-pagination-container {
    text-align: right;
  }
}
