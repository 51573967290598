.search-by-name-section .search-by-name {
  width: 100%;
  max-width: 400px;
  margin: 4rem auto;
}

@media (max-width: 991px) {
  .search-by-name-section .form-inline .form-group {
    flex-wrap: wrap;
    justify-content: center;
  }
  .search-by-name-section .search-by-name-submit {
    margin-top: 16px;
  }
}
