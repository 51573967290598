.save-search-picker-filter {
  width: 75%;
  display: inline-block;
}

.save-search-picker-search {
  width: 100%;
  display: inline-block;
}

@media (max-width: 700px) {
  .save-search-picker-filter {
    width: 65%;
    display: inline-block;
  }
}
