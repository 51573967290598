.user-account-row {
  padding: 1rem;
  background-color: #f5f5f5;
  border-bottom: 2px solid #eee;
}

.user-account-row:hover {
  background-color: #eee;
}

.user-account-row-edit {
  padding: 0.5rem;
}

.user-account-row-edit:hover {
  background-color: #ccc;
  cursor: pointer;
}
