.tree_thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 0.25rem;
  max-width: 100%;
}

.item-content {
  display: flex;
  margin-left: 0em;
  text-align: left;
}

.item-content h5 {
  margin-left: 0em;
  display: inline-block;
  word-break: break-word;
}

.item-content i {
  color: #767676;
}

.text-overflow {
  max-width: 55rem;
  width: 50vw;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ListItem:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
