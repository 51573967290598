.form-field-checkbox ul {
  padding-inline-start: 0px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .form-field-checkbox ul {
    justify-content: space-between;
  }
}

.form-field-checkbox li {
  text-decoration: none;
  display: inline-block;
  margin: 0 1rem 0.25rem 0;
}

.form-field-plain-text {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  outline: 0 !important;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 0 0.5rem;
  width: 100%;
}

.form-field-clear-field-button {
  cursor: pointer;
  background-color: black;
  color: white;
  width: 16px;
  font-size: 12px;
  height: 16px;
  text-align: center;
  margin-left: 8px;
  display: inline-block;
  border-radius: 100%;
}

.rdw-editor-wrapper {
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid hsl(0, 0%, 80%);
}

.public-DraftStyleDefault-block {
  margin: 0.5rem !important;
}

.rdw-editor-toolbar {
  margin-bottom: 0 !important;
  border-bottom-color: hsl(0, 0%, 90%) !important;
}

.error-message {
  font-size: 12px;
  color: red;
  display: none;
}

input:invalid[edited="true"] ~ .error-message {
  display: block;
}

.photoInput[edited="true"] ~ .error-message {
  display: block;
}

.TreeMenu__option {
  white-space: pre-wrap !important;
}

.tree-form--individual-photo {
  display: inline-block;
  margin: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
}

.tree-form--individual-photo:hover {
  background-color: rgba(0, 0, 0, 0.125);
}

.tree-form--individual-photo--image {
  height: 150px;
  overflow: hidden;
}

.tree-form--individual-photo img {
  height: 100%;
  margin: auto;
  display: block;
}

.tree-form--photo-gallery--dragging {
  width: 50px;
  height: 50px;
  border: 1px dashed grey;
}

.photo-edit,
.photo-delete,
.photo-check {
  width: 33%;
  padding: 0.5rem;
  text-align: center;
  display: inline-block;
  position: relative;
  color: #9b9b9b;
}

.photo-edit:hover,
.photo-delete:hover {
  color: #333333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.15);
}

.big-checkbox {
  height: 16px;
  width: 16px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 12px;
}

.big-checkbox:hover {
  background-color: rgba(0, 0, 0, 0.15);
  height: 22px;
  width: 22px;

  top: 0px;
  left: 9px;
}

.modal-edit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  background-color: #fff;
}

#photo-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 10002 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-modal-main {
  margin: auto;
  width: fit-content;
  cursor: pointer;
  background-color: white;
  border: 2px solid #444;
  border-radius: 4px;
  padding: 1rem;
  position: relative;
}

.photo-modal-main h2 {
  font-family: "urbane", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}

.photo-modal-button {
  padding: 15px 20px;
  border-radius: 4px;
  border: 2px solid black;
  background-color: white;
  color: #666;
  display: inline-block;
  cursor: pointer;
  z-index: 10003;
  position: absolute;
  margin-left: -30px;
  top: calc(50% - 30px);
}

.photo-modal-button:hover {
  background-color: #eaeaea;
  color: black;
}

.photo-modal-close.btn {
  cursor: pointer;
  top: 0px;
  right: 0px;
  padding: 5px 13px !important;
  border-radius: 100%;
  position: absolute;
  margin: -2rem;
  border: 2px solid black;
  box-shadow: 0 0 3px 1px #eaeaea;
}

@media (max-width: 720px) {
  .photo-modal-button {
    padding: 5px 10px;
    margin-left: -20px;
    top: calc(50% - 20px);
  }

  .photo-modal-main {
    padding: 1rem;
    padding-top: 4rem;
  }
  .photo-modal-close.btn {
    margin: 3rem;
  }
}
