.new-list-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em;
  font-size: medium;
  color: grey;
  background-color: rgb(228, 228, 228);
  width: 100%;
  height: 2em;
  border: dashed;
  margin-bottom: 1em;
}

.modal-scroll-div {
  max-height: 65vh;
  height: fit-content;
  overflow-y: scroll;
}

.new-list-container > * {
  margin: unset;
  padding: unset;
}

.new-list-container:hover {
  background-color: grey;
  color: white;
  border: solid;
}

svg:hover {
  cursor: pointer;
}

.list-widget {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.my-lists {
  min-width: fit-content;
  width: 100%;
}

.list-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 5em;
  width: 100%;
}

.list-card-container {
  border-bottom: 1px solid grey;
  padding: 0.5em;
  padding-left: 0;
}

.list-card .left-container {
  display: flex;
  flex-direction: row;
}

.list-card .details {
  display: flex;
  flex-direction: column;
  color: #767676;
  text-align: left;
  margin-left: 0.5em;
}

.list-widget-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.my-lists,
.list-widget-form h3 {
  text-align: center;
}

.description-section {
  min-height: 5em;
  max-height: 8em;
}

.keyword-section {
  margin-bottom: 1.2em;
}

.required-field {
  color: red;
}

.widget-actions > * {
  margin: 0;
}
