.list-header {
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.list-header-info {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 1em;
}

.main-content-scroll-div {
  height: fit-content;
  max-height: 41.4em;
  overflow-y: scroll;
}

.main-content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.carousel-control.right .glyphicon-chevron-right,
.carousel-control .icon-next {
  right: 0;
  margin-right: 0;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .prev-icon {
  left: 0;
  margin-left: 0;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
  filter: invert(100%);
}
