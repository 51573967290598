.tree-form--tree-char h3 {
  margin-top: 2rem;
}

.tree-form--photos .tree-form--photo-gallery {
  display: flex;
  flex-wrap: wrap;
}

.tree-form--individual-photo {
  width: calc(16.6% - 0.5rem);
  margin: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
}

.tree-form--individual-photo:hover {
  background-color: rgba(0, 0, 0, 0.125);
}

.tree-form--individual-photo--image {
  height: 150px;
  overflow: hidden;
}

.tree-form--individual-photo img {
  height: 100%;
  margin: auto;
  display: block;
}

.tree-form--photo-gallery--dragging {
  width: 50px;
  height: 50px;
  border: 1px dashed grey;
}

.photo-edit,
.photo-delete,
.photo-check {
  width: 33%;
  padding: 0.5rem;
  text-align: center;
  display: inline-block;
  position: relative;
  color: #9b9b9b;
}

.photo-edit:hover,
.photo-delete:hover {
  color: #333333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.15);
}

#photo-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 10002 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-modal-main {
  margin: auto;
  width: fit-content;
  cursor: pointer;
  background-color: white;
  border: 2px solid #444;
  border-radius: 4px;
  padding: 3rem;
  position: relative;
}

.photo-modal-main h2 {
  font-family: "urbane", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}

.photo-modal-button {
  padding: 15px 20px;
  border-radius: 4px;
  border: 2px solid black;
  background-color: white;
  color: #666;
  display: inline-block;
  cursor: pointer;
  z-index: 10003;
  position: absolute;
  margin-left: -30px;
  top: calc(50% - 30px);
}

.photo-modal-button:hover {
  background-color: #eaeaea;
  color: black;
}

.photo-modal-close.btn {
  cursor: pointer;
  top: 0;
  right: 0;
  padding: 5px 13px !important;
  border-radius: 100%;
  position: absolute;
  margin: 2rem;
  border: 2px solid black;
  box-shadow: 0 0 3px 1px #eaeaea;
}

@media (max-width: 720px) {
  .photo-modal-button {
    padding: 5px 10px;
    margin-left: -20px;
    top: calc(50% - 20px);
  }

  .photo-modal-main {
    padding: 1rem;
    padding-top: 4rem;
  }
  .photo-modal-close.btn {
    margin: 1rem;
  }
}

.taxon-edit-button {
  margin: 0 0.25rem 0 0.5rem;
  padding: 0.125rem 0.5rem 0.5rem;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.reorder-photos-drop-area {
  max-width: 80vw;
}

.reorder-photos-drop-area > div {
  display: flex;
  overflow-x: scroll;
  padding: 25px;
  background-color: #eee;
}

.tree-form--reorder-photo--image {
  height: 250px;
  overflow: hidden;
}

.tree-form--reorder-photo img {
  height: 100%;
  margin: auto;
  display: block;
  padding: 10px;
}

.photo-options .form-group {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}

.photo-options .form-group input {
  width: 100%;
}

.photo-options .form-group .form-button-primary,
.photo-options .form-group .form-button-secondary {
  margin: 12px auto;
}

.big-checkbox {
  height: 16px;
  width: 16px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 12px;
}

.big-checkbox:hover {
  background-color: rgba(0, 0, 0, 0.15);
  height: 22px;
  width: 22px;

  top: 0px;
  left: 9px;
}
