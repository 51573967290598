.responsive-accordion {
  width: 100%;
}

.responsive-accordion-title {
  border: none !important;
}

.responsive-accordion,
.responsive-accordion .card,
.responsive-accordion .card-body {
  height: 100%;
}

.responsive-accordion .card-body {
  padding: 0;
}

.responsive-accordion .card-body > .container {
  padding: 0 1.5rem 1.5rem;
}

.responsive-accordion h3 {
  margin: 0;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  .responsive-accordion h3 {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .responsive-accordion .panel-title svg {
    display: none;
  }
  /*Disallow close on desktop*/
  .responsive-accordion-collapse {
    display: block !important;
    height: 100% !important;
  }
}
