.tree-detail-gallery-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tree-detail-gallery-image {
  flex-basis: 24%;
  flex-grow: 1;
  margin: 5px;
  height: 200px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  object-fit: cover;
}

@media (max-width: 991px) {
  .tree-detail-gallery-image {
    flex-basis: 40%;
  }
}

@media (max-width: 360px) {
  .tree-detail-gallery-image {
    flex-basis: 100%;
  }
}

#modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background img {
  max-height: 75vh;
  max-width: 75vw;
  cursor: pointer;
  z-index: 10000;
  height: auto;
  width: auto;
}

.modal-main {
  margin: auto;
  width: fit-content;
  cursor: pointer;
  background-color: white;
  border: 2px solid #444;
  border-radius: 10px;
  padding: 3rem;
  position: relative;
}

.modal-button {
  padding: 15px 20px;
  border-radius: 4px;
  border: 2px solid black;
  background-color: white;
  color: #666;
  display: inline-block;
  cursor: pointer;
  z-index: 10001;
  position: absolute;
  margin-left: -30px;
  top: calc(50% - 30px);
}

.modal-button:hover {
  background-color: #eaeaea;
  color: black;
}

.modal-close.btn {
  cursor: pointer;
  top: 0;
  right: 0;
  padding: 5px 13px !important;
  border-radius: 100%;
  position: absolute;
  margin: 2rem;
  border: 2px solid black;
  box-shadow: 0 0 3px 1px #eaeaea;
}

@media (max-width: 720px) {
  .modal-button {
    padding: 5px 10px;
    margin-left: -20px;
    top: calc(50% - 20px);
  }

  .modal-main {
    padding: 1rem;
  }
  .modal-close.btn {
    margin: 1rem;
  }
}
