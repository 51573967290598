.footer-images {
  display: flex;
  justify-content: space-between;
}

.footer-img {
  width: 100%;
}

.footer {
  text-align: center;
  font-style: italic;
  background-color: #f5f5f5;
}

@media (max-width: 992px) {
  .footer-img {
    width: 75%;
  }
}
