.search-by-name {
  height: 45px;
  position: relative;
  width: 100%;
}

.search-by-name-container {
  width: 100%;
  border: 1px solid #999;
  position: absolute;
  border-radius: 22.5px;
  padding: 0.25rem 0.75rem;
  background-color: white;
  z-index: 1 !important;
}

@media (min-width: 992px) {
  .search-by-name-container {
    max-width: 400px;
  }
}

.search-by-name-input {
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  border: none;
}

.search-by-name-input:focus,
.search-by-name-input:active {
  border: none;
  outline: none;
  padding-bottom: 0.5rem;
  margin-bottom: 0.25rem;
  border-bottom: 2px solid #ccc;
}

.search-by-name-clear {
  position: absolute;
  background: none !important;
  border: none !important;
  right: 3rem;
}

.search-by-name-submit {
  border-radius: 20px;
  position: absolute;
  background-color: transparent !important;
  border: none;
  color: black;
  right: 0.75rem;
}

.search-by-name-submit.search-by-name-button-ready {
  background-color: #457537 !important;
  color: white !important;
}

.search-by-name-submit:focus,
.search-by-name-submit:active {
  border: none;
  outline: none;
}

.search-by-name-submit:hover {
  background-color: #eee !important;
}

.search-by-name-submit.search-by-name-button-ready:hover {
  background-color: #154507 !important;
}

.search-by-name-divider {
  margin: 0 auto;
  width: 90%;
}

.search-by-name-suggestion-box {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 50vh;
  position: relative;
  overflow-y: scroll;
  height: 100%;
}

.search-by-name-suggestion-card {
  border-bottom: 1px solid #eee;
  position: relative;
  cursor: pointer;
  padding: 4px;
  color: #444;
}

.persist-suggestion-hover {
  background-color: #457537;
  color: white;
}

.suggestion-card:hover {
  background-color: #457537;
  color: white;
}

.search-by-name-suggestion-card .label-medium,
.search-by-name-suggestion-card .small {
  text-transform: none !important;
}
