.upload {
  text-align: center;
}

.uploadbox {
  position: relative;
  margin: auto;
  border: 2px dotted lightgray;
  padding: 50px 350px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  cursor: pointer;
}

.results table {
  text-align: left;
  width: 100%;
  display: auto;
  overflow-x: scroll;
}

.results tr {
  text-align: left;
  border: 1px solid;
}

.results {
  overflow: scroll;
  height: 75vh;
  white-space: nowrap;
}

.results table {
  width: 100%;
  display: auto;
  overflow-x: scroll;
  margin: auto;
  text-align: left;
  display: auto;
  overflow: hidden;
  white-space: nowrap;
}

.results tr {
  min-width: 250px;
  border: 1px solid;
}

.results {
  overflow: scroll;
  height: 75vh;
  white-space: nowrap;
}
.results th {
  text-align: left;
  position: sticky;
  top: 0;
  min-width: 200px;
}
.header {
  display: inline-flex;
}

.upload-preview-btn {
  white-space: nowrap;
  overflow: hidden;
  padding: 0.1rem;
  margin-bottom: 0.5rem;
  background-color: transparent;
  border: none;
  background-repeat: no-repeat;
  outline: 0;
  outline-style: none;
  outline-width: 0;
  user-select: none;
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  text-align: center;
}

.box {
  position: relative;
  width: 50%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;

  text-align: center;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(26% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.alterAttr {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  outline: none;
  height: 30px;
}

.alterAttr::placeholder {
  text-align: center;
}
