.action-dropdown .dropdown-toggle::after {
  display: none !important;
}

.action-dropdown .nav-link {
  display: inline-block;
  width: fit-content;
  padding: 0 0;
}

.action-dropdown .dropdown .nav-item {
  width: fit-content;
}

.action-dropdown-container {
  display: inline-flex;
  align-self: center;
  margin-right: 0.3rem;
}

.action-icon {
  color: #457537;
  width: 30px;
}

.dropdown-item:active .action-icon {
  color: white;
}

.action-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
