.selectree-navbar-logo {
  margin-top: -10px;
  width: 150px;
}

.selectree-navbar {
  padding: 1rem 20px;
  font-family: "Arial Bold", Arial, Helvetica, sans-serif;
  border-bottom: 2px solid #000;
  position: fixed;
  z-index: 99999;
  width: 100%;
  max-width: 1920px;
  margin-top: -74px;
}

.navbar-quicklinks {
  display: none;
}

@media only screen and (max-width: 576px) {
  .selectree-navbar-logo {
    margin-top: -5px;
  }
}

@media (max-width: 992px) {
  .navbar-quicklinks {
    display: block;
  }

  .selectree-navbar {
    margin-top: -114px;
  }

  .navbar-toggler {
    padding: 0;
    border: 0;
  }
}

.selectree-navbar-search .form-group {
  position: relative;
}

.selectree-navbar-search .search-by-name-input input {
  border-radius: 20px;
  height: 28px;
}

.selectree-navbar-search .search-by-name-submit {
  font-size: 12px;
  padding: 0px 1rem;
  height: 26px;
  position: absolute;
  right: 0.4rem;
  background-color: white;
  color: #666;
  border: none;
}

.selectree-navbar-search .search-by-name-container .form-group {
  background: white;
  border-radius: 20px;
}

.selectree-navbar-search .rbt {
  margin-top: 1px;
}

.selectree-navbar-nav-group {
  width: 85%;
  margin: auto;
  justify-content: flex-end;
}

@media (min-width: 1406px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding: 0 1.5rem;
  }
}
