.list-description-toggle {
  display: none;
}

@media (max-width: 700px) {
  .list-description-container {
    overflow-y: hidden;
    height: 10rem;
  }

  .white-transparent-grad {
    background-image: linear-gradient(transparent, white 110%);
    z-index: 1;
  }

  .list-description-toggle {
    display: flex;
    justify-content: center;
    background-color: #2d2d2d;
    height: 1.5rem;
  }
  .list-description-toggle-text {
    z-index: 1;
    display: block;
    cursor: pointer;
    position: absolute;
    color: white;
    bottom: 0rem;
  }
}
