.download {
  text-align: center;
  overflow: hidden;
}

.crossed-line {
  text-decoration: line-through;
}

th {
  text-align: center;
  display: inline-flexbox;
  width: 300px;
  overflow: visible;
}

.column-filters button {
  margin: auto;

  display: block;

  margin-bottom: 0.5rem;
  background-color: transparent;
  border: none;
  background-repeat: no-repeat;
  outline: 0;
  outline-style: none;
  outline-width: 0;
  user-select: none;
}

.download-char {
  display: block;
  max-width: 100%;
  max-height: 750px;
  overflow-x: visible;
  overflow-y: visible;
}

.download-char h4 {
  text-align: center;
  display: block;
  padding: 0.25rem;
  margin-bottom: 1rem;
  cursor: pointer;
  user-select: none;
}

div.general {
  position: relative;
  top: 25px;
  left: 125px;
}

div.tree {
  position: relative;
  bottom: 565px;
  left: 525px;
}
div.tree2 {
  position: relative;
  bottom: 1075px;
  left: 725px;
}

div.toggle1 {
  position: relative;
  left: 400px;
  bottom: 150px;
}
div.treeheader {
  position: relative;
  bottom: 560px;
  left: 625px;
}

div.site {
  position: relative;
  bottom: 1550px;
  left: 975px;
}

div.considerations {
  position: relative;
  left: 1175px;
  bottom: 1955px;
}

div.selectall {
  position: relative;
  left: 1530px;
  bottom: 1800px;
  cursor: pointer;
  user-select: none;
}

div.climatezones {
  position: relative;
  left: 1400px;
  bottom: 2300px;
}

.Row button {
  display: block;
  margin-bottom: 5px; /* Add margin between buttons if needed */
}
