.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: #fff;
  padding: 20px;
  width: 500px;
  max-width: 95%;
  border-radius: 8px;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.modal-actions button {
  margin-left: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
}

.modal-actions button:hover {
  background-color: #f5f5f5;
}

.editor-toolbar {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.editor-toolbar button {
  margin: 0 5px;
  padding: 5px 10px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.editor-toolbar button:hover {
  background-color: #ddd;
}

.rich-text-editor {
  width: 100%;
  min-height: 200px;
  max-height: 350px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  overflow-y: auto;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.4;
}

.rich-text-editor:focus {
  outline: none;
  border-color: #aaa;
}

.rich-text-editor ul,
.rich-text-editor ol {
  margin-left: 1.5rem;
  line-height: 1.4;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  list-style-position: outside;
}

.tree-characteristic-glossary-entry {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}

.tree-characteristic-glossary-entry:last-child {
  border-bottom: none;
}

.tree-characteristic-glossary-title {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.tree-characteristic-glossary-title h3 {
  margin: 0;
  flex-grow: 1;
}

.glossary-entry-actions {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
}

.edit-icon,
.delete-icon {
  cursor: pointer;
  font-size: 1.2em;
  transition: color 0.2s ease;
}

.edit-icon:hover {
  color: #558f44;
}

.delete-icon:hover {
  color: #e25c5c;
}

.tree-characteristic-glossary-title a {
  text-decoration: none;
  color: inherit;
}

.tree-characteristic-glossary-text {
  font-family: "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.4;
  margin: 15px 0;
}

.tree-characteristic-glossary-text ul,
.tree-characteristic-glossary-text ol {
  margin-left: 1.5rem;
  line-height: 1.4;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  list-style-position: outside;
}

.back-to-top {
  margin-top: 10px;
}

.glossary-photos {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 1rem;
}

.glossary-photo {
  flex-basis: 24%;
  flex-grow: 1;
  margin: 5px;
  height: 200px;
  max-width: 400px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  cursor: pointer;
}

@media (max-width: 991px) {
  .glossary-photo {
    flex-basis: 40%;
  }
}

@media (max-width: 360px) {
  .glossary-photo {
    flex-basis: 100%;
  }
}

.photo-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.photo-upload-container {
  margin: 0;
}

.back-to-top {
  margin: 0;
}

.glossary-photo-container {
  position: relative;
  margin: 10px 0;
  width: calc(30% - 10px);
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 16px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.delete-photo-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #dc3545;
  transition: all 0.2s ease;
}

.delete-photo-button:hover {
  background: #dc3545;
  color: white;
}

.upload-photo-button {
  display: inline-block;
  padding: 8px 16px;
  margin-top: 10px;
  background-color: #457537;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.upload-photo-button:hover {
  background-color: #558f44;
}

.photo-upload-container {
  margin-top: 10px;
}

.add-term-container {
  margin-bottom: 1rem;
  text-align: center;
}

.add-new-term-button {
  background-color: #457537;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.add-new-term-button:hover {
  background-color: #35632e;
}

.glossary-photo {
  border-radius: 4px;
  margin-bottom: 12px;
}

.photo-text-editor-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.photo-editor-toolbar {
  background: #f5f5f5;
  border-radius: 4px 4px 0 0;
  padding: 8px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.photo-text-input {
  min-height: 100px;
  padding: 12px;
  border: 1px solid #e1e1e1;
  border-radius: 0 0 4px 4px;
  background: #fafafa;
  font-size: 12px;
  line-height: 1.5;
  color: #333;
}
