.collabButton {
  margin-left: 0.2rem;
  padding: 0.2rem 0.2rem;
  font-size: 14px;
  border-radius: 10px;
  border: none;
  box-shadow: 1px 1px 0px 2px rgba (0, 0, 0, 0.3);
  background: #457537;
  cursor: pointer;
  color: white;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.collabButton:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.collaboratorItem:hover {
  background: #efefef;
}
