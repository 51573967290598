.featured-tree-scientific-name {
  margin-top: -8px;
  cursor: pointer;
}

.featured-tree-container {
  padding: 2.2rem;
}
.featured-tree-label {
  cursor: pointer;
}
.featured-tree-mobile-label {
  margin: 0.5rem 0;
  cursor: pointer;
}

.featured-tree-image {
  cursor: pointer;
}

@media (max-width: 767px) {
  .featured-tree-container {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .featured-tree-text {
    text-align: left;
  }
}

@media (max-width: 991px) {
  /*Overblow the image and hide to overflow to get a "zoom in"*/
  .featured-tree-image {
    overflow: hidden;
  }
  .featured-tree-image img {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .featured-tree-image img {
    width: 100%;
    height: auto;
  }
}
