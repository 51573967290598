@keyframes loading-bar-anim {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.loading-bar {
  height: 100%;
  width: 100%;
  background-color: #457537;
  animation-name: loading-bar-anim;
  animation-duration: 4s;
  animation-iteration-count: 0;
}
