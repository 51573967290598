.tree-info-card {
  margin: 5px;
  border: none;
}

.tree-info-card-text {
  display: block;
  padding-bottom: 1.5rem;
}

/* removes padding for item below */
.tree-info-card-group {
  display: block;
  padding-bottom: 0rem;
}

.tree-info-card-text-cite {
  display: block;
  padding-bottom: 1.5rem;
  font-size: small;
}
