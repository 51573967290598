.result-per-page-btn {
  height: 100%;
}

.result-per-page .btn-primary.active {
  background-color: #457537 !important;
  color: #f5f5f5 !important;
}

.result-per-page .btn-primary:not(.active) {
  background-color: white;
  color: #444;
}

.result-per-page .btn-primary:hover {
  background-color: #315227;
  color: white;
}

.result-per-page-buttons {
  display: flex;
  width: fit-content;
}

@media (max-width: 991px) {
  .result-per-page-buttons {
    margin: auto;
  }
}

@media (min-width: 992px) {
  .result-per-page-buttons {
    display: inline-flex;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .result-per-page-buttons .result-per-page-btn {
    padding: 0 0.5rem;
  }
}
