.selectree-banner-container-h1 {
  font-size: 4rem;
}

.selectree-banner-container-h3 {
  font-family: "urbane", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.selectree-banner-container {
  height: 225px;
  background: url("/images/oak_leaf2.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  display: flex;
  align-items: center;
  padding: 2rem;
  margin-top: 74px;
}

.selectree-banner-container > .container {
  padding: 2.5rem;
}

@media (max-width: 992px) {
  .selectree-banner-container-h1 {
    font-size: 2.2rem;
  }

  .selectree-banner-container-h3 {
    font-size: 1.2rem;
  }

  .selectree-banner-container {
    height: auto;
    text-align: left;
    padding: 1rem;
    align-items: center;
  }

  .selectree-banner-container {
    margin-top: 114px;
  }
}

.selectree-banner-title {
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: #2d2d2d;
  width: fit-content;
}
