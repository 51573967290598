.quicklinks-container {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 10px;
}

.quick-links-link-text {
  margin-top: 16px;
  font-size: 14px !important;
  padding: 0 15px 0 15px;
}

.btn-circle-md {
  width: 38px;
  min-width: 38px;
  height: 38px;
  border-radius: 38px;
  text-align: left;
  color: white;
  background-color: gray;
}

.btn-icon {
  height: 19px;
  width: 19px;
  margin-top: 9px;
  margin-left: 9px;
}

.quicklinks-link:hover {
  text-decoration: underline;
}

@media (min-width: 992px) {
  .quicklinks-bg {
    margin-top: 1.2rem;
    height: 4.5rem;
    background-color: #f5f5f5;
  }

  .quicklinks-link-text {
    color: white;
  }

  .quicklinks-link {
    display: flex;
    align-items: flex-start;
  }

  .quicklinks-link > p {
    padding: 0 15px 0 15px;
    font-size: 14px !important;
  }

  .quicklinks-link-text {
    color: #444;
    width: 100%;
  }

  .quicklinks-container > .row {
    width: 100% !important;
  }
}

/* hide quicklinks if screen is less than 992px */
@media only screen and (max-width: 992px) {
  #quicklinks {
    display: none;
  }
}
