.static-list-container,
.list-content-container {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

@media (max-width: 1130px) {
  .static-list-container,
  .list-content-container {
    margin-top: 2.5em;
    display: flex;
    flex-direction: column;
    min-width: fit-content;
  }
}

.list-content-container {
  min-width: 65%;
}

.list-container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list-type.dropdown-toggle {
  left: unset;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  z-index: 1;
}

.btn-primary.dropdown-toggle {
  background-color: #f5f5f5;
}

.right-container-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.checkboxes {
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: space-between;
  align-content: space-between;
  gap: 0.5em;
}

.create-list-button {
  height: fit-content;
  width: 100%;
  padding-right: 1em;
  padding-left: 1em;
  /* border-radius: 0.2em 0.2em 0em 0em; */
  margin-bottom: 0.5em;
}

.list-group-item.active {
  z-index: 2;
  color: #2d2d2d;
  background-color: #45753733;
  border: 2px solid rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.other-view-description {
  display: flex;
  flex: row;
}

.popup-content.popup-content-content {
  display: flex;
  flex-direction: row;
  padding: 1em;
  align-items: center;
  text-align: left;
  border: none !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  font-size: 0.85em;
}

.popup-content.popup-content-content p {
  margin-bottom: 0.2em;
}

.popup-arrow {
  filter: none;
  stroke: rgba(128, 128, 128, 0.178);
}

.tree_thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 0.25rem;
  max-width: 100%;
}

.ListItem:hover {
  background-color: #f5f5f5;
}
