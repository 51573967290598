.result-gallery-card-container {
  display: inline-block;
}

.result-gallery-pagination {
  background-color: white;
  display: flex;
  margin: auto;
  width: fit-content;
  font-weight: bold;
}

.result-gallery-pagination .page-item .page-link {
  border-radius: 3px;
  margin: 0 2px;
  color: #2d2d2d;
  border: 1px solid transparent;
}

.result-gallery-pagination .box-link.page-item .page-link {
  border: 1px solid #ccc;
}

.result-gallery-pagination .page-item.active .page-link {
  border: none;
  color: white;
}

.result-gallery-card-link {
  color: inherit;
}

.result-gallery-end-pagination li {
  display: inline-block;
}

@media (max-width: 368px) {
  .result-gallery-end-pagination {
    display: none;
  }
}

@media (max-width: 460px) {
  .result-gallery-pagination .page-item .page-link {
    padding: 0.5rem !important;
  }
}
