.featured-quote-container {
  padding: 2.2rem;
}

.featured-quote-quotation {
  position: relative;
  top: -51px;
  z-index: 2;
  padding: 20px;
}

.featured-quote-quote-icon {
  font-size: 7rem;
  z-index: 1;
  position: relative;
  opacity: 0.5;
}

.featured-quote-citation {
  position: relative;
  top: -35px;
  font-size: 1.25rem;
}

@media (min-width: 992px) {
  .featured-quote-quotation {
    margin-left: 10%;
  }
  .featured-quote-citation {
    margin-left: 30%;
  }
}
